import AuthService from "./AuthService";
import moment from "moment/moment";

const Component = {
  runLogoutOnInactivityBackgroundJob: function () {
    const LAST_ACTIVITY_STORAGE = "lastActivityEpochMs";

    // Define functions

    const resetTimer = () => {
      window.localStorage.setItem(LAST_ACTIVITY_STORAGE, Date.now());
    };

    const doLogoutOnProlongedInactivity = () => {
      const lastActivityEpochMs = window.localStorage.getItem(
        LAST_ACTIVITY_STORAGE,
      );

      if (lastActivityEpochMs == null) {
        return; // It happens when it was not yet set, e.g. on re-login
      }

      const inactivityMs = Date.now() - lastActivityEpochMs;
      const maxInactivityMs =
        process.env.REACT_APP_MAX_INACTIVITY_MINUTES * 60 * 1000;
      if (inactivityMs > maxInactivityMs) {
        window.localStorage.clear(LAST_ACTIVITY_STORAGE);
        AuthService.clearIdToken();
        AuthService.logout();
        alert(
          "You have been logout for inactivity. (" +
            process.env.REACT_APP_MAX_INACTIVITY_MINUTES +
            " minutes)",
        );
      }
    };

    const doLogoutOnExpiredSession = () => {
      const sessionStartTime = moment(AuthService.getSessionStartTime());
      const sessionDurationMinutes = moment().diff(sessionStartTime, "minutes");
      const maxDurationMinutes =
        process.env.REACT_APP_MAX_SESSION_DURATION_MINUTES;
      if (sessionDurationMinutes >= maxDurationMinutes) {
        AuthService.logout();
        alert(
          "Your session has expired. Please login again. (" +
            process.env.REACT_APP_MAX_SESSION_DURATION_MINUTES +
            " minutes)",
        );
      }
    };

    // Run background job

    doLogoutOnProlongedInactivity(); // Do first check immediately
    doLogoutOnExpiredSession(); // Do first check immediately
    setInterval(doLogoutOnProlongedInactivity, 1000);
    setInterval(doLogoutOnExpiredSession, 1000);

    // Define when to reset timer

    resetTimer(); // Do first set immediately
    document.onmousedown = resetTimer;
    document.onkeydown = resetTimer;
  },
};

export default Component;
